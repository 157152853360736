.footer-container {
  background-color: #222222;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 50px 20px 50px 20px;
}

.footer-elementor-container {
  max-width: 1140px;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.footer-elementor-column {
  padding: 10px;
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-line-pack: start;
  align-content: flex-start; */
}

.footer-elementor-image {
  text-align: center;
}
.elementor-container-width {
  max-width: 1236px;
}

.footer-elementor-widget-container {
  padding: 0px 0px 20px 0px;
  -webkit-transition: background 0.3s, border 0.3s, border-radius 0.3s,
    -webkit-box-shadow 0.3s;
  transition: background 0.3s, border 0.3s, border-radius 0.3s,
    -webkit-box-shadow 0.3s;
  -o-transition: background 0.3s, border 0.3s, border-radius 0.3s,
    box-shadow 0.3s;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    -webkit-box-shadow 0.3s;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
}

.footer-elementor-image img {
  filter: brightness(0%) contrast(0%) saturate(0%) blur(0px) hue-rotate(0deg);
}
.footer-elementor-image img {
  vertical-align: middle;
  display: inline-block;
}
.footer-elementor-image img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.footer-elementor-image img {
  border-style: none;
  height: auto;
  max-width: 100%;
}

.footer-elementor-image img {
  width: 320px;
  aspect-ratio: auto 320 / 76;
  height: 76px;
}

.elementor-nav-menu--layout-vertical > ul > li > a {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.elementor-nav-menu {
  margin-right: auto;
}
.elementor-nav-menu,
.elementor-nav-menu li,
.elementor-nav-menu ul {
  display: block;
  list-style: none;
  margin: 0;
  line-height: normal;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.elementor-nav-menu {
  position: relative;
  z-index: 2;
}

dd,
dl,
dt,
li,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.menu-item-type-custom {
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
}

.elementor-item {
  color: #ffffff;
  fill: #ffffff;
  font-family: "Barlow", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 26px;
  letter-spacing: 3px;
  text-decoration: none;
}
.elementor-item:hover {
  color: #ad895a;
  fill: #ad895a;
}

.elementor-item:after {
  content: "";
  transform: scaleX(0);
}

.elementor-item:hover:after {
  background-color: #ad895a;
  bottom: 0;
  height: 3px;
  width: 100%;
  left: 0;
  z-index: 2;
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  display: block;
  position: absolute;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: transform 250ms ease-in-out;
  -o-transition: transform 250ms ease-in-out;
  -webkit-transition: transform 250ms ease-in-out;
}

.elementor-icon-list-text {
  font-family: "Barlow", Sans-serif;
  font-weight: 400;
  color: #adadad;
  display: inline-block;
  -ms-flex-item-align: center;
  align-self: center;
  padding-left: 8px;
}
.elementor-icon-list-item {
  padding-bottom: calc(40px / 2);
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: inherit;
}

.sub-footer {
  text-align: center;
  color: #adadad;
  font-family: "Barlow", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.sub-footer-icon-box-title {
  font-family: "Barlow", Sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.4em;
  margin-bottom: 0px;
  color: #adadad;
}

.sub-footer-icon-box-description {
  color: #adadad;
  font-family: "Barlow", Sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.bg-dark-custom {
  background: #000000;
}

.btn-send {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ad895a;
  --bs-btn-border-color: #ad895a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ad895a;
  --bs-btn-hover-border-color: #ad895a;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ad895a;
  --bs-btn-active-border-color: #ad895a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ad895a;
  --bs-btn-disabled-border-color: #ad895a;
  font-family: "Barlow", Sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.btn:focus {
  box-shadow: none !important;
}
.footer-logo {
  filter: brightness(0%) contrast(0%) saturate(0%) blur(0px) hue-rotate(0deg);
}
