.gallery-about {
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  border-color: #ad895a;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 20px;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 250px;
}

.gallery-about:hover .elementor-gallery-item__overlay {
  transform: scale(1.1);
}

.elementor-gallery-item__overlay {
  -webkit-transition-duration: 800ms;
  -o-transition-duration: 800ms;
  transition-duration: 800ms;
  -webkit-transition-property: mix-blend-mode, opacity, background-color,
    -webkit-transform;
  transition-property: mix-blend-mode, opacity, background-color,
    -webkit-transform;
  -o-transition-property: mix-blend-mode, transform, opacity, background-color;
  transition-property: mix-blend-mode, transform, opacity, background-color;
  transition-property: mix-blend-mode, transform, opacity, background-color,
    -webkit-transform;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center center;
  background-size: cover;
  width: 100%;
  transform-origin: center top;
  transition: 350ms;
}

.gallery-elementor-widget-wrap {
  align-content: flex-start;
  align-items: flex-start;
  padding: 20px 20px 20px 20px;
  /* display: flex; */
  position: relative;
  width: 100%;
  min-height: 250px;
}

.gallery-about:hover .gallery-elementor-widget-wrap {
  background: #2222228f;
}
