.reviews-container {
  background-color: #f9f4f4;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 100px 20px 0px 20px;
}

.reviews-heading-title {
  color: #222222;
  font-family: "Barlow Condensed", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 22px;
  letter-spacing: 6px;
}

.reviews-heading-title-sub {
  color: #222222;
  font-family: "Gilda Display", Sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 56px;
}

.elementor-counter-number-wrapper {
  color: #ad895a;
  font-family: "Gilda Display", Sans-serif;
  font-size: 75px;
  font-weight: 600;
  line-height: 1;
}

.elementor-counter-title {
  font-family: "Barlow", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: #54595f;
  text-align: center;
}

.reviews-widget-container {
  padding: 10px 0;
}

.progress {
  height: 44px !important;
  line-height: 44px !important;
  font-family: "Barlow", Sans-serif !important;
  font-size: 16px !important;
  border-radius: 2px !important;
  background-color: #666666 !important;
  -webkit-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
}

.progress-bar {
  background-color: #ad895a;
  line-height: 44px;
  font-family: "Barlow", Sans-serif;
  font-size: 16px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 15px;
  justify-content: start !important;
  -webkit-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  flex-direction: inherit !important;
}

.reviews-title {
  color: #666666;
  font-family: "Barlow", Sans-serif;
  font-size: 20px;
  font-weight: 400;
}
