.logo {
  text-align: center;
  width: 250px;
  color: #ad895a;
  font-weight: 900;
  font-family: "Gilda Display", Sans-serif;
}
.swiper-slide {
  height: 100vh !important;
}
.swiper-slide-bg {
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  min-width: 100%;
  min-height: 100%;
}

.elementor-background-overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
}

.swiper-slide-inner {
  align-items: center;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-repeat: no-repeat;
  background-position: 50%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* padding: 50px; */
  margin: auto;
}

.swiper-slide-contents {
  max-width: 66%;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}
.animated {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
}

.elementor-slide-heading:not(:last-child) {
  margin-bottom: 7px;
}

.elementor-slide-heading {
  font-family: "Gilda Display", Sans-serif;
  font-size: 55px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 74px;
  letter-spacing: 15px;
}

.elementor-slide-description {
  font-family: "Barlow Condensed", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 6px;
  line-height: 1.4;
}
.elementor-slide-description:not(:last-child) {
  margin-bottom: 30px;
}

.elementor-swiper-button svg {
  display: inline-block;
  font-family: eicons;
  font-size: inherit;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 32px;
}

.elementor-swiper-button-prev {
  left: 10px;
}

.elementor-swiper-button-next {
  right: 10px;
}
.elementor-swiper-button {
  position: absolute;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  z-index: 1;
  cursor: pointer;
  font-size: 25px;
  color: #ffffff75;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.about_container {
  background-color: #ffffff;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 100px 20px 100px 20px;
  --widgets-spacing: 0px;
}

.elementor-container {
  max-width: 1140px;
}
.elementor-container {
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.elementor-heading-title {
  color: #666666;
  font-family: "Barlow Condensed", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 22px;
  letter-spacing: 6px;
}
.elementor-widget-container {
  margin: 0px 0px 5px 0px;
}
.elementor-widget-container {
  -webkit-transition: background 0.3s, border 0.3s, border-radius 0.3s,
    -webkit-box-shadow 0.3s;
  transition: background 0.3s, border 0.3s, border-radius 0.3s,
    -webkit-box-shadow 0.3s;
  -o-transition: background 0.3s, border 0.3s, border-radius 0.3s,
    box-shadow 0.3s;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    -webkit-box-shadow 0.3s;
}

.elementor-widget-container-sub {
  width: 80%;
  max-width: 80%;
}
.elementor-heading-title-sub {
  color: #222222;
  font-family: "Gilda Display", Sans-serif;
  font-size: 45px;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 56px;
  margin: 0px 0px 20px 0px;
}
.elementor-text-editor {
  color: #666666;
  font-family: "Barlow", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
}

.elementor-widget-container-image {
  padding: 10px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    -webkit-box-shadow 0.3s;
  text-align: center;
}

.elementor-widget-image img {
  vertical-align: middle;
  display: inline-block;
  height: 340px;
  object-fit: cover;
  max-width: 100%;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.elementor-widget-image-top {
  position: relative;
  top: 104px;
}

.faq-container {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 100px 20px 100px 20px;
  --widgets-spacing: 0px;
  background-color: #f9f4f4;
}

.accordion {
  --bs-accordion-bg: transparent !important;
  --bs-accordion-border-color: transparent !important;
}

.accordion-button:not(.collapsed) {
  color: #000 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}
.accordion-button::after {
  position: absolute;
  left: 0;
}

.accordion-link {
  color: #222222;
  line-height: 1;
  margin: 0;
  padding: 20px 15px;
  outline: none;
  font-family: "Barlow", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}

.accordion-body {
  border-width: 0px;
  color: #666666;
  font-family: "Barlow", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px 10px 40px !important;
}

.elementor-share-btn {
  overflow: hidden;
  font-size: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-transition-property: background-color, border-color, -webkit-filter;
  transition-property: background-color, border-color, -webkit-filter;
  -o-transition-property: filter, background-color, border-color;
  transition-property: filter, background-color, border-color;
  transition-property: filter, background-color, border-color, -webkit-filter;
  height: 4.5em !important;
  cursor: pointer;
  transition-duration: 0.2s;
  border: 2px solid #222222 !important;
  margin-bottom: 15px;
}
.elementor-share-btn:hover {
  border: 2px solid #ad895a;
}
.elementor-share-btn:hover .elementor-share-btn__icon {
  background-color: #ad895a;
  color: #ffff;
}

.elementor-share-btn:hover .elementor-share-btn__text {
  color: #ad895a;
}

.w-80 {
  width: 80%;
}

.elementor-share-btn__icon {
  background-color: #222222;
  color: #ffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 4.5em;
  position: relative;
}

.react-share__ShareButton {
  font: 0.6em Gilda Display, sans-serif !important;
}
.elementor-share-btn__text {
  font-size: 1.5em;

  padding-right: 1em;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 100;
  padding-left: 0.9em;
  font-weight: 700;
}

.services-container {
  background-color: #222222;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 100px 20px 0px 20px;
  position: relative;
}

.elementor-heading-heading {
  color: #ffffff;
  font-family: "Gilda Display", Sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 56px;
  text-transform: uppercase;
  letter-spacing: 6px;
}
.services-about {
  border-radius: 0px 0px 0px 0px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #ad895a59;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 20px 20px 20px 20px;
  --e-column-margin-right: 20px;
  --e-column-margin-left: 20px;
  width: 100%;
  position: relative;
}

.services-background-overlay {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.05;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.services-elementor-widget-wrap {
  align-content: flex-start;
  align-items: flex-start;
  padding: 20px 20px 20px 20px;
  position: relative;
  width: 100%;
  min-height: 350px;
}

.services-elementor-widget-container {
  text-align: left;
  width: 100%;
  max-width: 100%;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    -webkit-box-shadow 0.3s;
}

.elementor-icon {
  color: #ad895a;
  border-color: #ad895a;
  font-size: 37px;
}

.service-elementor-heading-title-sub {
  color: #ffff;
  font-family: "Gilda Display", Sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  margin: 20px 0;
}

.service-elementor-text-editor {
  color: #adadad;
  font-family: "Barlow", Sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.header {
  /* position: fixed; */
  z-index: 9;
  min-height: 96px;
  display: flex;
  align-items: center;
  height: 96px;
}
.header-scroll {
  background: white;
  transition: all 0.3s ease !important;
  transform: translatey(0px);
  animation: fadeIn 1.5s;
}
.header-scroll .elementor-item {
  color: #000;
}

.header-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.header-menu-item-type-custom {
  padding: 15px 0;
  position: relative;
}

.header-menu-item-type-custom a {
  padding: 0px 15px;
  font-family: "Barlow Condensed", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 26px;
  letter-spacing: 3px;
  color: #ffffff;
  fill: #ffffff;
}

.elementor-slide-button {
  font-family: "Barlow Condensed", Sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 18px;
  letter-spacing: 3px;
  border-width: 1px;
  border-radius: 0px;
  border-color: #ffffff;
  background: transparent;
  color: inherit;
  text-decoration: none;
  border: 2px solid #fff;
  color: #fff;
  background: transparent;
  display: inline-block;
  padding: 12px 24px;
  fill: #fff;
  text-align: center;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.elementor-slide-button:hover {
  color: #fff;
  background-color: #ad895a;
}

.question-button {
  font-family: "Barlow Condensed", Sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 26px;
  fill: #ad895a;
  color: #ad895a;
  background-color: #ffffff00;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-radius: 0px 0px 0px 0px;
  transition-duration: 0.3s;
  transition-property: transform;
  width: auto;
  display: inline-block;
  background-color: #ffffff00;
  padding: 12px 24px;
  text-align: center;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  text-decoration: none;
}

.question-button-wrapper {
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.question-button-icon {
  margin-right: 20px;
}

.question-button:hover {
  transform: scale(0.9);
  color: #fff;
  background-color: #ad895a;
}

.view-all-services {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 40px 20px 100px 20px;
}

.header-menu-item-type-custom .active {
  color: #ad895a;
  fill: #ad895a;
}

.header-menu-item-type-custom .active:after {
  background-color: #ad895a;
  content: "";
  bottom: 0;
  height: 3px;
  width: 100%;
  left: 0;
  z-index: 2;
  transform: scale(1);
  display: block;
  position: absolute;
  transition: 0.3s;
  transition-timing-function: cubic-bezier(0.58, 0.3, 0.005, 1);
}

.modal-dialog {
  max-width: 650px !important;
}

.modal-content {
  border: none !important;
  border-radius: 0 !important;
}

.modal-header {
  border: none !important;
}
.modal-title {
  font-family: "Gilda Display", Sans-serif;
  font-size: 24px !important;
  font-weight: 400 !important;
  color: #222222 !important;
}

.modal-title:after {
  content: "*";
  color: red;
  padding-left: 0.2em;
}

.modal-footer {
  border: none !important;
}

@media (max-width: 1200px) {
  .header-menu-item-type-custom a {
    padding: 0px 8px;
  }
}

.custom-menu {
  right: 20px;
  position: absolute;
  /* background: #ad895a; */
  border: none;
  top: 15px;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28173, 137, 90, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

@media (max-width: 767px) {
  .elementor-slide-heading {
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 2px;
  }
}

@media (max-width: 500px) {
  .navbar-brand img {
    width: 120px;
  }
}

@media (min-width: 992px) and (max-width: 1500px) {
  .navbar-nav {
    width: 90% !important;
  }
  .navbar-brand img {
    width: 250px;
  }
}
@media (max-width: 768px) {
  .elementor-button-wrapper {
    text-align: center;
  }
  .elementor-share-btn {
    width: 100% !important;
  }
}

@media (max-width: 992px) {
  .header {
    position: unset;
    height: unset;
  }
  .elementor-nav-menu {
    display: unset !important;
  }
}

.navbar-toggler {
  margin-right: 15px;
  background: transparent;
  border: unset;
}

.navbar-collapse.collapse.show {
  background: white;
  text-align: center;
}
.navbar-collapse.collapse.show a {
  color: #111;
}

.navbar-collapse.collapse.show .navbar-nav {
  width: 100% !important;
}
.navbar-collapse.collapse.show li {
  border-bottom: 1px solid #111;
}
