.surrounding_container{
    margin-bottom: 50px;
}
.surrounding-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 24px;
}
.block_section_container {
    margin-bottom: 40px;
}
.ul_list {
    list-style-type: none !important;
    padding-left: 0;
    /* margin: 0 0 16px 0; */
}
.list_item {
    margin: 0;
    padding: 16px 0;
    border-bottom: 1px solid #e7e7e7;
}
.list_item:last-child {
    border: none !important;
  }
.description_distance {
    display: flex;
    justify-content: space-between;
}
.disclaimer {
    margin: 0 0 4px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #6b6b6b;
}
.surroundings_title{
    margin-bottom: 16px !important;
}
.icon_surroundings{
    vertical-align: text-bottom;
    margin-right: 10px;
}
.text_title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}
@media only screen and (min-width:375px) and (max-width:780px){
    .surrounding_container {
        margin-left: 40px;
    }
}
