.location-container {
  background-color: #ffffff;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 100px 20px 100px 20px;
}

.location-heading-title {
  color: #222222;
  font-family: "Barlow Condensed", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 22px;
  letter-spacing: 6px;
}

.location-heading-title-sub {
  color: #222222;
  font-family: "Gilda Display", Sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 56px;
}

.location-widget-container {
  padding: 20px 20px 20px 20px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    -webkit-box-shadow 0.3s;
}
.location-text-editor {
  color: #666666;
  font-family: "Barlow", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
}

.location-video {
  margin-top: 0px;
  margin-bottom: 100px;
  position: relative;
}
.location-video-container {
  max-width: 700px;
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.location-video-widget-container {
  overflow: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: background 0.3s, border 0.3s, border-radius 0.3s,
    -webkit-box-shadow 0.3s;
  transition: background 0.3s, border 0.3s, border-radius 0.3s,
    -webkit-box-shadow 0.3s;
  -o-transition: background 0.3s, border 0.3s, border-radius 0.3s,
    box-shadow 0.3s;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s,
    -webkit-box-shadow 0.3s;
}

.location-fit-aspect-ratio {
  filter: brightness(84%) contrast(100%) saturate(100%) blur(0px)
    hue-rotate(0deg);
  padding-bottom: 56.25%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  height: 0;
  z-index: 99;
}

.elementor-custom-embed-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50%;
  cursor: pointer;
  text-align: center;
}

.elementor-custom-embed-play {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.elementor-fit-aspect-ratio {
  padding-bottom: 56.25%;
}

.elementor-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 0;
  background-color: #000;
}
