.rules_container{
    margin-bottom: 50px;
}
.rules_title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    padding: 0;
}
.rules_button {
    float: right;
    color: white;
    background-color: #0071c2;
    border: 1px solid #0071c2;
    border-radius: 2px;
    padding: 8px 16px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
}
.button_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}
.rules_subtitle {
    display: block;
    margin-top: 5px;
    color: #6b6b6b;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 16px;
    padding: 0;
}
.rules_block_container {
    padding: 0 24px 0 16px;
    margin: 0;
    font-size: 14px;
    border: 0;
    border-radius: 5px;
    background: #ebf3ff;
}
.check_in {
    line-height: unset !important;
    height: 16px !important;
    width: 320px !important;
    border-radius: 10px !important;
   
}
.check_in .progress-bar {
    position: relative !important;
    left: 192px !important;
}
.check_out {
    line-height: unset !important;
    height: 16px !important;
    width: 320px !important;
    border-radius: 10px !important;
}
.time_check_in {
    display: inline-block;
    margin: 7px 0 0;
    padding: 4px 10px;
    border: 1px solid #c2c2c2;
    border-radius: 3px;
    background: #fff;
    color: #333;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
}
.check_in_extra {
    display: block;
    color: #6b6b6b;
    font-size: 14px;
    padding-top: 1em;
}
x
.check_in_pointer:before {
    border-color: rgba(194,194,194,0); 
    border-bottom-color: #c2c2c2;
    border-width: 7px;
    margin-left: -7px; 
}
.check_in_pointer {
    position: absolute;
    bottom: 100%;
    left: 50%;
}
.policy_name {
    font-size: 14px;
    font-weight: 600;
}
.timebar__label {
    display: inline-block;
    position: absolute;
    bottom: 100%;
    margin: 0 0 2px;
    text-align: center;
    font-size: 12px;
}
.time_in_start {
    position:relative;
    left:174px;
}
.time_in_end {
    position:relative;
    left:265px;
}
.time_out_start {
    position: relative;
    left: 145px;
}
.description {
    margin: 0.6em 0;
    padding-bottom: 1.2em;
}
.child_policy {
    margin: 0 0 16px;
    font-size: 14px;
}
.child-h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 16px;
    padding: 0;
}
.child_span {
    margin: 8px 0 0;
    padding: 0;
}
.policy_name_icon {
    width: 20px;
    margin-right: 8px;
}
.booking_link {
    color: #0071c2;
    font-weight: bold;
    margin-left: 2px;
}
.booking_link:visited {
    color: #6b6b6b;
    font-weight: bold;
}
.booking_link:hover {
    color: #bc5b01;
}
.timebar__caption-pointer {
    position: absolute;
    bottom: 100%;
    left: 50%;
}
 .timebar__caption-pointer:before {
    position: absolute;
    bottom: 100%;
    left: 0;
    border: solid transparent;
    height: 0;
    width: 0;
    pointer-events: none;
    content: ' ';
}
 .timebar__caption-pointer:before {
    border-color: rgba(194,194,194,0);
    border-bottom-color: #c2c2c2;
    border-width: 7px;
    margin-left: -7px;
}
.timebar__caption-pointer:after {
    position: absolute;
    bottom: 100%;
    left: 0;
    border: solid transparent;
    height: 0;
    width: 0;
    pointer-events: none;
    content: ' ';
    border-color: rgba(255,255,255,0);
    border-bottom-color: #FFF;
    border-width: 6px;
    margin-left: -6px;
} 
.timbar_checkout {
    position: relative;
    right: 50px;
    top: -5px;
}
.timbar_checkout:before {
    position: absolute;
    bottom: 100%;
    left: 0;
    border: solid transparent;
    height: 0;
    width: 0;
    pointer-events: none;
    content: ' ';
}
 .timbar_checkout:before {
    border-color: rgba(194,194,194,0);
    border-bottom-color: #c2c2c2;
    border-width: 7px;
    margin-left: -7px;
}
.timbar_checkout:after {
    position: absolute;
    bottom: 100%;
    left: 0;
    border: solid transparent;
    height: 0;
    width: 0;
    pointer-events: none;
    content: ' ';
    border-color: rgba(255,255,255,0);
    border-bottom-color: #FFF;
    border-width: 6px;
    margin-left: -6px;
} 

@media only screen and (min-width:375px) and (max-width:780px){
    .rules_container {
        margin-left: 40px;
    }
    .check_in {
        width: 290px !important;
    }
    .time_in_end {
        left: 227px;
    }
    .check_out {
        width: 290px !important;
    }
   
}


