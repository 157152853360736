.rate-section {
  background-attachment: fixed;
  background-image: url("/src/assets/images/TraveliNN-2.jpg");
  background-position: 0px 27px;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  --widgets-spacing: 0px;
  position: relative;
}
